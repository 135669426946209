<template>
<div class="content">

    <div class="back button" @click="$router.push({ name: 'Register' });">
        Wróć do rejestracji
    </div>

    <div class="content-inside">

        <h2>Polityka prywatności Serwisu Alcon Academy</h2>

         <h4 class="title">1. Wstęp</h4>

        <p>Zdajemy sobie sprawę, że Twoje dane osobowe jako Użytkownika serwisu <a href="http://www.konkurs.alconacademy.pl">www.konkurs.alconacademy.pl</a>  (dalej: "Serwis") i sposób ich wykorzystania nie są Ci obojętne,
            dlatego chcemy, abyś miał pewność, że Alcon Polska Sp. z o.o. z siedzibą w Warszawie, ul. Marynarska 15, 02-674 Warszawa, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m.st.
            Warszawy w Warszawie, XIII Wydział Gospodarczy pod numerem KRS 0000060964, NIP 527-109-31-05, REGON 011429418, wysokość kapitału zakładowego 750 000,00 PLN,  (dalej: "Usługodawca") zachowuje należytą ostrożność,
            wykorzystując Twoje dane.</p>
        <p>Ochrona danych osobowych zawsze była traktowana przez Usługodawcę jako jeden z najważniejszych aspektów w działalności Usługodawcy. Czujemy się szczególnie odpowiedzialni za bezpieczeństwo Twoich danych osobowych przetwarzanych przez
            nas w związku z naszą działalnością.</p>
        <p>Naszym celem jest również należyte informowanie Użytkowników Serwisu, o sprawach związanych z przetwarzaniem danych osobowych. Z tego względu, w niniejszym dokumencie informujemy o podstawach prawnych przetwarzania Twoich danych
            osobowych, sposobach ich zbierania i wykorzystywania, a także o prawach podmiotów danych z tym związanych. Polityka prywatności zawiera także regulacje dot. "Cookies" i "Logów systemowych".</p>
        <p>W niniejszej Polityce Prywatności dowiesz się również kto jest administratorem Twoich danych osobowych, w jakim celu, zakresie i przez jaki okres będą one przetwarzane oraz jak długo przez nas przechowywane.</p>
        <p>Dokument ten został zrealizowany w zgodzie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego
            przepływu takich danych (dalej: "RODO").</p>
        <p>Niniejsza Polityka Prywatności ma zastosowanie do wszystkich przypadków, w których Usługodawca jest administratorem danych osobowych i przetwarza dane osobowe. Dotyczy to zarówno przypadków, w których Usługodawca przetwarza dane
            osobowe pozyskane bezpośrednio od osoby, której dane dotyczą, jak i przypadków, w których dane osobowe Usługodawca pozyskał z innych źródeł.</p>
        <p>Polityka Prywatności została skonstruowana w formie pytań i odpowiedzi. Wybór takiej formy został podyktowany dbałością o przejrzystość i czytelność prezentowanych Państwu informacji.</p>


         <h4 class="title">2. Zasady ogólne przetwarzania danych osobowych</h4>

        <p>Usługodawca bardzo dużą uwagę przywiązuje do właściwej ochrony Twoich danych osobowych oraz Twojej prywatności oraz dokłada wszelkich starań w celu zapewnienia poszanowania Twojej prywatności i ochrony Twoich danych osobowych podczas
            korzystania przez Ciebie z Serwisu.</p>
        <p>Dane osobowe oznaczają informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej (osobie, której dane dotyczą). Przetwarzaniem danych osobowych jest w zasadzie każda czynność na danych osobowych, niezależnie od
            tego, czy jest dokonywana w sposób zautomatyzowany czy nie, np. zbieranie, przechowywanie, utrwalanie, porządkowanie, modyfikowanie, przeglądanie, wykorzystywanie, udostępnianie, ograniczanie, usuwanie lub niszczenie.</p>
        <p>Usługodawca przetwarza dane osobowe w różnych celach, przy czym w zależności od celu, zastosowanie mogą mieć różne sposoby zbierania, podstawy prawne przetwarzania, wykorzystywania, ujawniania oraz okresy przechowywania danych
            osobowych.</p>
        <p>Użytkownicy odwiedzający Serwis, mają kontrolę nad danymi osobowymi, które nam podają. Serwis ogranicza zbieranie i wykorzystywanie informacji o Użytkownikach do niezbędnego minimum, wymaganego do świadczenia na ich rzecz usług na
            pożądanym poziomie, stosownie do art. 18 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz. U. nr. 144 poz. 1204).</p>
        <p>Dane osobowe oraz inne informacje zebrane na temat Użytkowników przetwarzane są w sposób zgodny z zakresem udzielonej przez Użytkownika zgody, wyłącznie w określonym celu oraz zgodnie z wymogami prawa polskiego, a w szczególności w
            zgodzie z przepisami:</p>
        <ul>
            <li>ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz. U. z 2002 r Nr 144, poz. 1204);</li>
            <li>ustawy z dnia 10 maja 2018 roku o ochronie danych osobowych (Dz.U. 2018 poz.1000);</li>
            <li>Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia
                dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) (Dz. Urz. UE. L. Nr 119, str. 1);</li>
        </ul>
        <ul>
            <li>ustawy z dnia 4 maja 2019 r o zmianie niektórych ustaw w związku z zapewnieniem stosowania rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
                przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), (D.Z. U. z 2019 r. poz. 730).</li>
        </ul>

         <h4 class="title">3. Kto jest administratorem Twoich danych osobowych?</h4>
        <p>Administratorem Twoich danych osobowych jest Usługodawca, czyli <a name="_Toc23172057"></a><a name="_Toc23841482"></a>Alcon Polska Sp. z o.o. z siedzibą w Warszawie, ul. Marynarska 15, 02-674 Warszawa, wpisana do rejestru
            przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy pod numerem KRS 0000060964, NIP 527-109-31-05, REGON 011429418, wysokość kapitału zakładowego 750
            000,00 PLN,</p>
        <p>Z kim możesz kontaktować się w sprawie przetwarzania Twoich danych osobowych?</p>
        <p>W sprawach związanych z ochroną danych osobowych oraz szeroko pojętą prywatnością możesz kontaktować się pod adresem e-mail: <a name="_Toc23172058"></a><a name="_Toc23841483"></a> ochrona.danych-alcon@Alcon.com&gt;</p>

         <h4 class="title">4. Jakie informacje na Twój temat przetwarzamy?</h4>
        <p>W zależności od celu, możemy przetwarzać następujące informacje na Twój temat:</p>
        <ul>
            <li>imię i nazwisko,</li>
            <li>adres e-mail,</li>
            <li>numer PWZ</li>
            <li>dane zawarte w korespondencji do nas kierowanej,</li>
        </ul>
        <ul>
            <li>informacje o systemie operacyjnym i przeglądarce internetowej, z której korzystasz,</li>
            <li>źródło, z którego przechodzisz do naszej strony,</li>
        </ul>
        <p>Zakres przetwarzanych danych opisaliśmy precyzyjnie w odniesieniu do każdego celu przetwarzania. Informacje w tym zakresie znajdują się w dalszej części niniejszej Polityki.</p>

         <h4 class="title">5. Skąd posiadamy Twoje dane osobowe?</h4>
        <p>W większości przypadków, Użytkownicy naszego Serwisu sami je przekazują Usługodawcy. Dzieje się tak szczególnie, gdy Użytkownik:</p>
        <ul>
            <li>rejestruje konto,</li>
            <li>rozwiązuje zadania konkursowe,</li>
            <li>przesyła reklamacje lub odstępuje od umowy,</li>
            <li>kontaktuje się z nami,</li>
        </ul>

        <p>Ponadto, część informacji na Twój temat może być automatycznie gromadzona przez narzędzia, z których korzystamy:</p>
        <ul>
            <li>mechanizm Serwisu gromadzi Twój adres IP</li>
            <li>Google Analytics gromadzi szereg informacji na temat tego, w jaki sposób korzystasz z naszej strony.</li>
        </ul>

         <h4 class="title">6. Czy Twoje dane są bezpieczne?</h4>
        <p>Usługodawca bierze pełną odpowiedzialność za przetwarzane dane osobowe. Usługodawca stosuje techniczne i organizacyjne środki bezpieczeństwa (np. protokół SSL, szyfrowanie danych, ograniczenie i kontrola dostępu do danych), mające
            zapewnić poufność, ochronę przed niedozwolonym lub niezgodnym z prawem przetwarzaniem oraz przypadkową utratą, zniszczeniem lub uszkodzeniem Twoich danych. Twoje dane przetwarzane są wyłącznie przez upoważnione do tego osoby i
            podmioty, które zachowują je w poufności.</p>
        <p>Usługodawca uwzględniając stan wiedzy technicznej, koszt wdrażania oraz charakter, zakres, kontekst i cele przetwarzania oraz ryzyko naruszenia praw lub wolności osób fizycznych o różnym prawdopodobieństwie wystąpienia i wadze
            zagrożenia, stosuje w Serwisie odpowiednie środki techniczne i organizacyjne, aby zapewnić stopień bezpieczeństwa odpowiadający temu ryzyku w celu ochrony danych i informacji na Twój temat.</p>
        <p>Powiadomienia wysyłane drogą elektroniczną przygotowywane są przez Usługodawcę do Użytkowników, wysyłane są z poszanowaniem wszelkich standardów przyjętych w komunikacji internetowej oraz nie zawierają zarówno zainfekowanych wirusami
            komputerowymi załączników, jak i linków do stron internetowych zawierających wirusy lub wyłudzających dane.</p>


         <h4 class="title">7. W jakich celach przetwarzamy Twoje dane osobowe?</h4>

        <p>Przetwarzamy Twoje dane w więcej niż jednym celu. Poniżej znajduje się ich lista, a następnie bardziej szczegółowe omówienie. Poszczególnym celom przyporządkowaliśmy również odpowiednie podstawy prawne przetwarzania.</p>
        <ul>
            <li>rejestracja i utrzymywanie konta użytkownika &ndash; art. 6 ust. 1 lit. b RODO,</li>
            <li>obsługa reklamacji lub odstąpienia od umowy &ndash; art. 6 ust. 1 lit. f RODO,</li>
            <li>rozwiązywanie zadań konkursowych - art. 6 ust. 1 lit. b RODO,</li>
            <li>obsługa korespondencji &ndash; art. 6 ust. 1 lit. f RODO,</li>
            <li>tworzenie archiwum na potrzeby ewentualnej konieczności obrony, ustalenia lub dochodzenia roszczeń&ndash; art. 6 ust. 1 lit. f RODO,</li>
            <li>marketing własny &ndash; art. 6 ust. 1 lit. f RODO,</li>
            <li>wysyłka nagrody - art. 6 ust. 1 lit. b RODO,</li>
        </ul>
        <p>Konto użytkownika &ndash; szczegóły</p>
        <p>Zakładając konto Użytkownika, musisz podać dane niezbędne do założenia konta i są to: adres e-mail oraz hasło w postaci numeru PWZ. Podanie danych jest dobrowolne, ale niezbędne do założenia konta.</p>
        <p>Co więcej, wykorzystujemy integrację narzędzia Google Analytics z mechanizmem konta użytkownika. W ten sposób, dane zgromadzone przez kod śledzący Google Analytics na temat Twojego korzystania z naszego Serwisu przypisywane są do
            Twojego konta Użytkownika. Chodzi tu o informacje takie jak:</p>
        <ul>
            <li>informacje o systemie operacyjnym i przeglądarce internetowej, z której korzystasz,</li>
            <li>przeglądane postronny,</li>
            <li>czas spędzony w Serwisie,</li>
            <li>przejścia pomiędzy poszczególnymi podstronami Serwisu,</li>
            <li>kliknięcia w poszczególne linki,</li>
            <li>źródło, z którego przechodzisz do naszego Serwisu.</li>
        </ul>

        <p>Analizujemy takie informacje na Twój temat w celu optymalizacji naszego Serwisu pod kątem doświadczenia Użytkownika, skuteczności i konwersji, co stanowi nasz prawnie uzasadniony interes, o którym mowa w art. 6 ust. 1 lit. f RODO.</p>
        <p>W każdej chwili możesz zmodyfikować informacje na Twój temat przekazane nam w związku z rejestracją konta użytkownika. W tym celu skontaktuj się z nami na adres e-mail: [&hellip;].</p>
        <p>Dane przetwarzane w związku z założeniem konta przetwarzane, są w celu świadczenia na Twoją rzecz usługi drogą elektroniczną polegającej na zapewnieniu Ci możliwości korzystania z konta użytkownika. Usługa ta świadczona jest na
            podstawie umowy zawieranej na zasadach opisanych w regulaminie. Podstawą prawną przetwarzania Twoich danych osobowych jest art. 6 ust. 1 lit. b RODO.</p>
        <p>Dane będą przechowywane przez czas funkcjonowania konta użytkownika. W każdej chwili możesz podjąć decyzję o usunięciu konta.</p>

        <h2>Obsługa korespondencji &ndash; szczegóły</h2>
        <p>Kontaktując się z nami, przekazujesz nam swoje dane zawarte w treści korespondencji, w szczególności adres e-mail oraz imię i nazwisko. Podanie danych jest dobrowolne, ale niezbędne, by nawiązać kontakt.</p>
        <p>Twoje dane w takim przypadku przetwarzane w celu kontaktu z Tobą, a podstawą przetwarzania jest art. 6 ust. 1 lit. f RODO, czyli nasz prawnie uzasadniony interes. Podstawą prawną przetwarzania po zakończeniu kontaktu jest również nasz
            usprawiedliwiony cel w postaci archiwizacji korespondencji na potrzeby zapewnienia sobie możliwości wykazania określonych faktów w przyszłości (art. 6 ust. 1 lit. f RODO).</p>
        <p>Treść korespondencji może podlegać archiwizacji i Usługodawca nie jest w stanie jednoznacznie określić, kiedy zostanie ona usunięta. Masz prawo do domagania się przedstawienia historii korespondencji, jaką z nami prowadziłeś (jeżeli
            podlegała archiwizacji), jak również domagać się jej usunięcia, chyba że jej archiwizacja jest uzasadniona z uwagi na nasze nadrzędne interesy, np. obrona przed potencjalnymi roszczeniami z Twojej strony.</p>

        <h2>Archiwum &ndash; szczegóły</h2>
        <p>W ramach opisu poszczególnych celów przetwarzania danych osobowych, które znajdują się powyżej, wskazaliśmy terminy przechowywania danych osobowych. Terminy te często związane są z archiwizacją przez nas określonych danych na potrzeby
            zapewnienia sobie możliwości wykazania określonych faktów w przyszłości, odtworzenia przebiegu współpracy z klientem, wymienionej korespondencji, obrony, ustalenia lub dochodzenia roszczeń. Opieramy się w tym zakresie na naszym
            prawnie uzasadnionym interesie, o którym mowa w art. 6 ust. 1 lit. f RODO.<a name="_Toc23841497"></a></p>


         <h4 class="title">8. Jak długo będziemy przechowywać Twoje dane osobowe?</h4>
        <p>Okresy przechowywania danych zostały wskazane odrębnie w stosunku do każdego celu przetwarzania. Odnajdziesz te informacje w<a name="_Toc23841498"></a> pkt. 7 powyżej. </p>

         <h4 class="title">9. Kim są odbiorcy Twoich danych osobowych?</h4>
        <p>Korzystamy z usług świadczonych przez podmioty trzecie. Część z tych usług wiąże się z przetwarzaniem Twoich danych osobowych. Zewnętrzni usługodawcy, którzy biorą udział w przetwarzaniu Twoich danych osobowych, to:</p>
        <ul>
            <li>hostingodawca, który przechowuje dane na serwerze,</li>
            <li>podmiot świadczący usługi w zakresie obsługi informatycznej, który uzyskuje dostęp do danych w celu utrzymania i obsługi Serwisu,</li>
            <li>podmiot świadczący usługi marketingowe,</li>
            <li>pozostali podwykonawcy, którzy uzyskują dostęp do danych, jeżeli zakres ich działań wymaga takowego dostępu.</li>
            <li>kancelaria prawna, która uzyskuje dostęp do danych, jeżeli jest to konieczne do świadczenia pomocy prawnej na naszą rzecz,</li>
        </ul>

        <p>Ponadto, w razie zaistnienia takiej konieczności, Twoje dane osobowe mogą być udostępniane podmiotom, organom lub instytucjom uprawnionym do uzyskania dostępu do danych na podstawie przepisów prawa, takim jak policja, sąd,
            prokuratura.</p>

         <h4 class="title">Czy przekazujemy Twoje dane do państw trzecich lub organizacji międzynarodowych?</h4>
        <p>Korzystamy z narzędzi zewnętrznych, które mogą gromadzić anonimowe informacje na Twój temat. Dostawcy tych narzędzi często wykorzystują do przechowywania gromadzonych informacji serwery zlokalizowane na terenie całego świata, w
            szczególności w Stanach Zjednoczonych Ameryki.</p>

         <h4 class="title">Czy korzystamy z profilowania? Czy podejmujemy w sposób zautomatyzowany decyzja na podstawie Twoich danych osobowych?</h4>
        <p>Nie podejmujemy wobec Ciebie decyzji opierających się wyłącznie na zautomatyzowanym przetwarzaniu, w tym profilowaniu, które wywoływałyby wobec Ciebie skutki prawne lub w podobny sposób istotnie na Ciebie wpływały.</p>
        <p>Owszem, korzystamy z narzędzi, które mogę podejmować określone działania w zależności od informacji zebranych w ramach mechanizmów śledzących, ale uważamy, że działania te nie mają istotnego wpływu na Ciebie, ponieważ nie różnicują
            Twojej sytuacji jako klienta, nie wpływają na warunki umowy, jaką możesz z nami zawrzeć itp.</p>
        <p>W ramach narzędzi, z których korzystamy nie mamy dostępu do informacji, które pozwalałby na Twoją identyfikację. Informacje, o których tutaj mówimy, to, w szczególności:</p>
        <ul>
            <li>informacje o systemie operacyjnym i przeglądarce internetowej, z której korzystasz,</li>
            <li>przeglądane podstrony,</li>
            <li>czas spędzony na stronie,</li>
            <li>przejścia pomiędzy poszczególnymi podstronami,</li>
            <li>źródło, z którego przechodzisz do naszej strony,</li>
        </ul>
        <p>Informacji wskazanych powyżej nie zestawiamy z Twoimi danymi osobowymi, które znajdują się w naszych bazach. Informacje te mają charakter anonimowy i nie pozwalają nam na Twoją identyfikację. Informacje te przechowywane są na serwerach
            dostawców poszczególnych narzędzi, a serwery te najczęściej mogą znajdować się na całym świecie.</p>
        <p>Wyjątkiem od anonimowego charakteru informacji, o których mowa powyżej, jest sytuacja, w której posiadasz konto użytkownika. Wtedy informacje te mogą być zestawiane z Twoimi danymi zgromadzonymi w koncie użytkownika. W dalszym ciągu
            jednak nie podejmujemy, z wykorzystaniem tych informacji, wobec Ciebie decyzji opierających się wyłącznie na zautomatyzowanym przetwarzaniu, w tym profilowaniu, które wywoływałyby wobec Ciebie skutki prawne lub w podobny sposób
            istotnie na Ciebie wpływały. Uważamy, że działania w zakresie kierowania do Ciebie reklam w zależności od Twojej aktywności na naszej stronie oraz podejmowanie działań optymalizacyjnych nie wpływa na Ciebie w istotny sposób. Dlatego w
            tym zakresie opieramy się na naszym prawnie uzasadnionym interesie, o którym mowa w art. 6 ust. 1 lit. f RODO.</p>
         <h4 class="title">
            Prawa przysługujące Tobie w stosunku do danych, które przetwarzamy
        </h4>
        <p>Użytkownicy mają określone prawa dotyczące swoich danych osobowych, a Usługodawca jako administrator tych danych odpowiada za realizację tych praw zgodnie z obowiązującymi przepisami prawa. W przypadku wszelkich pytań i próśb
            dotyczących zakresu i realizacji praw, a także w celu skontaktowania się z nami właśnie w celu skorzystania z określonego uprawnienia w zakresie ochrony danych osobowych prosimy o kontakt pod adresem e-mail: <a
                href="mailto:ochrona.danych-alcon@Alcon.com">ochrona.danych-alcon@Alcon.com</a></p>

         <h4 class="title">Dostęp do danych osobowych</h4>

        <p>Osoby fizyczne mają prawo dostępu do danych, które przechowujemy jako administrator danych. Prawo to można wykonywać poprzez wysłanie e-maila na adres:</p>
        <p><a href="mailto:ochrona.danych-alcon@Alcon.com">ochrona.danych-alcon@Alcon.com</a></p>

         <h4 class="title">Wycofanie zgody</h4>

        <p>W przypadku przetwarzania danych osobowych na podstawie zgody, osoby fizyczne mają prawo w dowolnej chwili wycofać tę zgodę. Informujemy, o tym prawie w każdym momencie zbierania zgód i umożliwiamy wycofanie zgody w tak łatwy sposób, w
            jaki jej nam udzielono. W braku odmiennej informacji, to znaczy jeśli nie podaliśmy innej możliwości wycofania zgody (np. przez użycie odpowiedniego przycisku wyboru "checkbox" celem wycofania zgody), prosimy o przesłanie
            do nas maila na adres:</p>
        <p><a href="mailto:ochrona.danych-alcon@Alcon.com">ochrona.danych-alcon@Alcon.com</a></p>
        <p>Zmiana danych osobowych</p>
        <p>Zmiany, w tym aktualizację swoich danych osobowych, które przetwarza Usługodawca można dokonać poprzez wysłanie maila na adres e-mail:</p>
        <p><a href="mailto:chrona.danych-alcon@Alcon.com">chrona.danych-alcon@Alcon.com</a></p>

        <p>Prawo do ograniczenia przetwarzania lub wniesienia sprzeciwu wobec przetwarzania danych osobowych</p>
        <p>Osoby fizyczne mają prawo ograniczenia przetwarzania lub wniesienia sprzeciwu wobec przetwarzania swoich danych osobowych w dowolnej chwili, ze względu na ich szczególną sytuację, chyba że przetwarzanie jest wymagane zgodnie z
            przepisami prawa. Osoba fizyczna może wnieść sprzeciw wobec przetwarzania jej danych osobowych, gdy:</p>
        <ul>
            <li>przetwarzanie danych osobowych odbywa się na podstawie prawnie uzasadnionego interesu lub dla celów statystycznych, a sprzeciw jest uzasadniony przez szczególną sytuację, w której się znalazła,</li>
            <li>dane osobowe przetwarzane są na potrzeby marketingu bezpośredniego, w tym są profilowane dla tego celu.</li>
        </ul>
        <p>Z kolei w odniesieniu do żądania ograniczenia przetwarzania danych, przysługuje ono np. gdy dana osoba zauważy, że jej dane są nieprawidłowe. Wówczas może żądać ograniczenia przetwarzania swoich danych na okres pozwalający nam
            sprawdzić prawidłowość tych danych.</p>
        <ul>
            <li>Prawo żądania usunięcia danych i prawo do przenoszenia danych</li>
        </ul>
        <p>Z prawa do usunięcia danych można skorzystać np. gdy dane osoby fizycznej nie będą już niezbędne do celów, dla których zostały zebrane przez Usługodawcę albo gdy osoba fizyczna wycofa swoją zgodę na przetwarzanie danych przez
            Usługodawcę. Nadto, w przypadku, gdy osoba fizyczna zgłosi sprzeciw wobec przetwarzania jej danych lub gdy jej dane będą przetwarzane niezgodnie z prawem. Dane powinny być również usunięte w celu wywiązania się z obowiązku
            wynikającego z przepisu prawa.</p>
        <p>Z kolei prawo do przenoszenia danych przysługuje wówczas, gdy przetwarzanie danych osoby odbywa się na podstawie zgody osoby fizycznej lub umowy zawartej z nią oraz, gdy przetwarzanie to odbywa się w sposób automatyczny.</p>
        <p>W przypadku chęci realizacji tych praw, należy wysłać maila na adres:</p>
        <p><a href="mailto:ochrona.danych-alcon@Alcon.com">ochrona.danych-alcon@Alcon.com</a></p>

         <h4 class="title">
            13. Pliki "Cookies", "Informacje systemowe"
        </h4>
        <p>W trakcie poruszania się po Serwisie mogą być gromadzone informacje w sposób bierny z Twojej strony (tzn. bez aktywnego dostarczania tych informacji przez Ciebie). Informacje te są przekazywane z użyciem różnych technologii takich jak
            pliki cookies lub logi systemów, są one bezpieczne dla urządzenia, z którego korzystasz, nie powodują zmian konfiguracyjnych oraz nie przenoszą wirusów oraz niechcianego lub złośliwego oprogramowania.</p>
        <p>Co to są informacje systemowe (logi systemów)?</p>
        <p>Używana przez Państwa przeglądarka internetowa automatycznie przekazuje nam dane, w postaci logów systemowych (zapisów informatycznych). Są to informacje takie jak: adres (URL) witryny internetowej odwiedzanej przez Ciebie poprzednio,
            adres IP, wersja przeglądarki aktualnie wykorzystywanej przez Twój komputer, a także czas dostępu i przesłana ilości danych.</p>
        <p>Usługodawca może analizować opisane powyżej logi systemów. Informacje uzyskane w ten sposób wykorzystywane są w celach administracyjnych (rozpoznawania i usuwania problemów dotyczących pracy serwerów, w tym naruszeń ich bezpieczeństwa)
            oraz do badań statystycznych. Informacje te nie są łączone z Twoimi danymi osobowymi.</p>
        <p>Co to są pliki "Cookies"?</p>
        <p>Pliki "cookies" to małe pliki tekstowe wysyłane przez Serwis, który właśnie odwiedzasz i zapisywane są na urządzeniu końcowym np. komputerze, smartfonie, tablecie, z którego korzystasz podczas przeglądania naszego Serwisu.
            W "cookies", składających się z szeregu liter i cyfr, znajdują się różne informacje niezbędne do prawidłowego funkcjonowania naszego Serwisu.</p>
        <p>Pliki "Cookies" nie zawierają danych identyfikujących Użytkowników, a na ich podstawie nie można ustalić czyjejkolwiek tożsamości. Pliki te nie są w żaden sposób szkodliwe dla Twojego urządzenia i nie zmieniają jego
            ustawień ani ustawień zainstalowanego na nim oprogramowania. Odczytywanie zawartości tych plików możliwe jest jedynie przez serwer, który je utworzył, dzięki czemu wyświetlana strona jest lepiej dopasowana do indywidualnych
            preferencji Użytkownika. "Cookies" identyfikują dane komputera i przeglądarki używanej do przeglądania stron internetowych. Dzięki nim możemy np. dowiedzieć się, czy dany komputer już odwiedzał stronę. Dane pozyskane z
            "cookies" nie są w żaden sposób łączone z danymi osobowymi użytkowników pozyskanymi np. podczas rejestracji w Serwisie. Pliki cookies wykorzystywane przez Serwis mogą mieć charakter:</p>
        <ul>
            <li>Stałe pliki "cookies": są przechowywane także po zakończeniu korzystania przez Państwa z Serwisu i służą do przechowywania informacji np. w celu uwierzytelnienia w ramach Serwisu co znacznie przyspiesza i ułatwia
                korzystanie z naszego Serwisu. Trwałe pliki cookies mogą pozostawać na komputerze dni, miesiące a nawet lata lub do czasu ich usunięcia przez użytkownika.</li>
            <li>Tymczasowe pliki "cookies" (sesyjne): są usuwane z chwilą zamknięcia przeglądarki, z której Państwo korzystali podczas przeglądania naszego Serwisu.</li>
            <li>"Funkcjonalne" pliki "cookies", umożliwiające "zapamiętanie" wybranych przez Użytkownika ustawień i personalizację interfejsu Użytkownika, np. w zakresie wybranego języka lub regionu, z którego
                pochodzi Użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp.</li>
            <li>"Cookies" służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w ramach Serwisu.</li>
            <li>Cookies" podmiotów zewnętrznych &ndash; (ang. third parties cookies) &ndash; to informacje pochodzące np. z serwerów reklamowych, serwerów firm i dostawców usług współpracujących z właścicielem danej strony internetowej. Są
                zamieszczane w Twoim urządzeniu przez osoby trzecie niezależnie od Usługodawcy. Rola Usługodawcy ogranicza się wyłącznie do umożliwienia podmiotom zewnętrznym umieszczenia pliku, które korzystają z informacji dostarczanych przez
                zewnętrzne pliki cookie Informacje te pozyskuje i zarządza nimi wyłącznie podmiot zewnętrzny, a nie Usługodawca.</li>
        </ul>
        <p>Jak wyłączyć pliki "Cookies" w przeglądarce?</p>
        <p>Każdy Użytkownik Internetu może decydować jakie pliki "cookies" chce używać i może je dostosować do swoich indywidualnych potrzeb i preferencji, w tym całkowicie zablokować możliwość pozostawiania plików
            "cookies" lub usunąć stałe pliki cookies, wykorzystując stosowne opcje przeglądarki internetowej, z której Użytkownicy korzystają. Jednak wyłączenie stosowania "cookies" może spowodować utrudnienia korzystania
            z niektórych usług w ramach naszego Serwisu.</p>
        <p>Użytkownicy korzystający z urządzenia przenośnego (telefon, smartfon, tablet), powinni zapoznać się z opcjami dotyczącymi ochrony prywatności w dokumentacji na stronie internetowej producenta urządzenia mobilnego.</p>
        <p>Niedokonanie zmiany ustawień w zakresie "cookies" oznacza, że będą one zamieszczone w urządzeniu końcowym Użytkownika, a tym samym będziemy przechowywać te informacje w urządzeniu końcowym Użytkownika i uzyskiwać dostęp do
            tych informacji.</p>
        <p>Informacje na temat ustawień lub całkowitego wyłączenia plików "cookies" w poszczególnych przeglądarkach dostępne są na poniższych stronach producentów popularnych przeglądarek lub w sekcji "Pomoc" w menu
            przeglądarki internetowej.</p>
        <ul>
            <li>Internet Explorer: http://support.microsoft.com/kb/278835/pl</li>
            <li>Safari: http://support.apple.com/kb/PH5042</li>
            <li>Bing: http://www.microsoft.com/PrivacyStatement/pl-pl/Bing/Default.aspx#EAB</li>
            <li>Chrome: https://support.google.com/chrome/answer/95647?hl=pl</li>
            <li>Mozilla Firefox: <a href="https://support.mozilla.org/pl/kb/usuwanie-ciasteczek">https://support.mozilla.org/pl/kb/usuwanie-ciasteczek</a></li>
        </ul>
        <p>Dostępne są również wtyczki do przeglądarek umożliwiające kontrolę nad plikami cookies, takie jak np. Ghostery (<a href="https://www.ghostery.com">https://www.ghostery.com</a>). Opcję kontroli nad plikami cookies może zapewniać również
            dodatkowe oprogramowanie, w szczególności pakiety antywirusowe itp. Ponadto, w Internecie dostępne są narzędzia pozwalające na kontrolę nad niektórymi rodzajami plików cookies, w szczególności na zbiorowe zarządzanie ustawieniami
            reklamy behawioralnej (np. <a href="http://www.youronlinechoices.com/">www.youronlinechoices.com/</a>, <a href="http://www.networkadvertising.org/choices">www.networkadvertising.org/choices</a>).</p>
        <p>W telefonach komórkowych, tabletach i innych urządzeniach mobilnych Każdy model urządzenia może obsługiwać zarządzanie plikami cookies w inny sposób. Dlatego zachęcamy do zapoznania się z opcjami prywatności w dokumentacji na stronie
            internetowej producenta Państwa urządzenia mobilnego.</p>
        <p>Jakie pliki cookies podmiotów trzecich są przez nas wykorzystywane?</p>
        <p>W ramach naszego Serwisu wykorzystujemy następujące pliki cookies podmiotów trzecich:</p>
        <ul>
            <li>Google Analytics,</li>
            <li>Google Tag Manager,</li>
            <li>Google AdWords,</li>
            <li>Google AdSense,</li>
            <li>Facebook, LinkedIN, (pliki cookies narzędzi społecznościowych),</li>
            <li> </li>
        </ul>
        <p>Szczegóły dotyczące poszczególnych plików cookies podmiotów trzecich opisane zostały poniżej.</p>
        <h2>Google Analytics - szczegóły</h2>
        <p>Korzystamy z narzędzia Google Analytics zapewnianego przez Google LLC z siedzibą przy 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Działania w tym zakresie realizujemy, opierając się na naszym prawnie uzasadnionym
            interesie, polegającym na tworzeniu statystyk i ich analizie w celu optymalizacji Serwisu.</p>
        <p>W celu korzystania z Google Analytics, zaimplementowaliśmy w kodzie naszego Serwisu specjalny kod śledzący Google Analytics. Kod śledzący wykorzystuje pliki cookies firmy Google LLC dotyczące usługi Google Analytics. W każdej chwili
            możesz zablokować kod śledzący Google Analytics, instalując dodatek do przeglądarki udostępniany przez Google: <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.</p>
        <p>Google Analytics w sposób automatyczny gromadzi informacje o Twoim korzystaniu z Serwisu. Zgromadzone w ten sposób informacje są najczęściej przekazywane do serwerów Google, które mogą znajdować się na całym świecie i tam
            przechowywane.</p>
        <p>Z uwagi na aktywowaną przez nas anonimizację adresu IP, Twój adres IP jest skracany przed przekazaniem dalej. Tylko w wyjątkowych przypadkach pełny adres IP jest przekazywany do serwerów Google i dopiero tam skracany. Zanonimizowany
            adres IP przekazany przez Twoją przeglądarkę w ramach Google Analytics co do zasady nie jest łączony z innymi danymi Google.</p>
        <p>Podkreślamy, że w ramach Google Analytics nie gromadzimy jakichkolwiek danych, które pozwalałaby na Twoją identyfikację.</p>
        <p>W ramach Funkcji Reklamowych również nie gromadzimy danych osobowych. Informacje, do jakich mamy dostęp, to, w szczególności:</p>
        <ul>
            <li>Twoje zainteresowania określone na podstawie aktywności w sieci.</li>
        </ul>
        <p>Jeżeli jesteś zainteresowany szczegółami związanymi z wykorzystywaniem przez Google danych z witryn i aplikacji, które korzystają z usług Google, zachęcamy do zapoznania się z tymi informacjami:  <a
                href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</a>.</p>
        <h2>Google Tag Manager &ndash; szczegóły</h2>
        <p>Korzystamy z narzędzia Google Tag Manager zapewnianego przez Google LLC z siedzibą przy 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA i umożliwiającego zarządzanie tagami, czyli drobnymi fragmentami kodu, dzięki którym
            jesteśmy w stanie</p>
        <p>kontrolować ruch i zachowanie Użytkowników, zbierać informacje o skuteczności reklam i</p>
        <p>podejmować działania mające na celu ulepszanie naszego Serwisu. Google Tag Manager</p>
        <p>nie zbiera żadnych informacji umożliwiających Twoją identyfikację, jednakże narzędzie to powoduje uruchomienie innych tagów, które z kolei mogą zbierać dane.</p>
        <h2>Google AdWords - szczegóły</h2>
        <p>Korzystamy z funkcji remarketingowych dostępnych w ramach systemu Google AdWords obsługiwanego przez Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Działania w tym zakresie realizujemy, opierając się na naszym
            prawnie uzasadnionym interesie, polegającym na marketingu własnych produktów lub usług.</p>
        <p>Przy okazji odwiedzin naszego Serwisu automatycznie pozostawiany jest w Twoim urządzeniu plik remarketing cookie firmy Google, który zbiera informacje na temat Twojej aktywności w Serwisie. Dzięki zgromadzonym w ten sposób informacją
            jesteśmy w stanie wyświetlać Ci reklamy w ramach sieci Google w zależności od Twoich zachowań na naszej stronie. Przykładowo, jeżeli wyświetlisz jakiś produkt, informacja o tym fakcie zostanie odnotowana przez plik remarketing cookie,
            co sprawi, że będziemy mogli skierować do Ciebie reklamę dotyczącą tego produktu lub jakąkolwiek inną, którą uznamy za właściwą. Reklama ta zostanie wyświetlona Ci w ramach sieci Google podczas korzystania przez Ciebie z Internetu,
            przeglądania innych stron internetowych itp.</p>
        <p>Usłygodawca korzystając z Google Ads, nie gromadzimy jakichkolwiek danych, które pozwalałaby na Twoją identyfikację. Ewentualne zestawienie danych w taki sposób, że nabierają one charakteru danych osobowych, może być dokonywane po
            stronie Google, ale w tym zakresie nie ponosimy już za to odpowiedzialności, ponieważ Google realizuje te działania na podstawie umowy zawartej z Tobą jako użytkownikiem usług Google. </p>
        <p>Jeżeli nie chcesz otrzymywać personalizowanych reklam, możesz zarządzać ustawieniami reklam bezpośrednio po stronie Google: <a href="https://adssettings.google.com/">https://adssettings.google.com/</a>. </p>
        <p>Jeżeli jesteś zainteresowany szczegółami związanymi z wykorzystywaniem przez Google danych z witryn i aplikacji, które korzystają z usług Google, zachęcamy do zapoznania się z tymi informacjami:  <a
                href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</a>.</p>
        <h2>Google AdSense &ndash; szczegóły</h2>
        <p>Wyświetlamy na naszych stronach reklamy w ramach sieci Google AdSense obsługiwanej przez Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Działania w tym zakresie realizujemy, opierając się na naszym prawnie
            uzasadnionym interesie, polegającym na monetyzacji treści przez nas publikowanych.  </p>
        <p>Nasz Serwis, w związku z wyświetlaniem na niej reklam w ramach sieci AdSense, zawiera tagi reklam, które wydają Twojej przeglądarce internetowej polecenie wysłania żądania treści reklamowej z serwerów Google. Wraz z treścią reklamową
            serwer wysyła także plik cookie. Pliki cookies wykorzystywane są do wyświetlania reklam na podstawie Twoich poprzednich odwiedzin na naszej stronie lub w innych witrynach internetowych. AdSense używa plików cookie również po to, by
            poprawiać jakość reklam. Często zastosowania to m.in. kierowanie reklam na podstawie tematów, które Cię interesują, ulepszanie raportów o skuteczności kampanii i pomijanie reklam, które już widziałeś.</p>
        <p>Usługodawca korzystając z Google AdSense, nie gromadzimy jakichkolwiek danych, które pozwalałaby na Twoją identyfikację. Ewentualne zestawienie danych w taki sposób, że nabierają one charakteru danych osobowych, może być dokonywane po
            stronie Google, ale w tym zakresie nie ponosimy już za to odpowiedzialności, ponieważ Google realizuje te działania na podstawie umowy zawartej z Tobą jako użytkownikiem usług Google. </p>
        <p>Jeżeli nie chcesz otrzymywać personalizowanych reklam, możesz zarządzać ustawieniami reklam bezpośrednio po stronie Google: <a href="https://adssettings.google.com/">https://adssettings.google.com/</a>. </p>
        <p>Jeżeli jesteś zainteresowany szczegółami związanymi z wykorzystywaniem przez Google danych z witryn i aplikacji, które korzystają z usług Google, zachęcamy do zapoznania się z tymi informacjami:  <a
                href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</a>.</p>
        <h2>Narzędzia społecznościowe &ndash; szczegóły</h2>
        <p>W naszym Serwisie używane są wtyczki, przyciski i inne narzędzia społecznościowe, zwane dalej łącznie "wtyczkami", udostępniane przez serwisy społecznościowe takie jak Facebook, LinkedIN.</p>
        <p>Wyświetlając naszą stronę internetową zawierającą wtyczkę danego serwisu społecznościowego, Twoja przeglądarka przesyła do administratora tego serwisu społecznościowego informację o odwiedzinach. Ponieważ wtyczka jest fragmentem
            serwisu społecznościowego wbudowanym w naszą stronę, przeglądarka wysyła informację o żądaniu pobrania zawartości danego serwisu społecznościowego na naszą stronę.</p>
        <p>Wtyczki gromadzą pewne informacje na Twój temat, takie jak identyfikator użytkownika, odwiedzana witryna, data i godzina oraz inne informacje dotyczące przeglądarki internetowej.</p>
        <p>Administratorzy serwisów społecznościowych wykorzystują część tych informacji, aby personalizować warunki oglądania naszej strony. Na przykład, gdy odwiedzasz stronę z przyciskiem "Lubię to", administrator serwisu
            społecznościowego potrzebuje informacji, kim jesteś, aby pokazać Ci, którzy z Twoich znajomych również lubią naszą stronę. </p>
        <p>Informacje gromadzone przez wtyczki mogą być również wykorzystywane przez administratorów serwisów społecznościowych w ich własnych celach, takich jak np. doskonalenie własnych produktów, tworzenie profili użytkowników, analiza i
            optymalizacja własnych działań, targetowanie reklam. Nie mamy realnego wpływu na to, w jaki sposób informacje gromadzone przez wtyczki są następnie wykorzystywane przez administratorów serwisów społecznościowych. Szczegółów w tym
            zakresie możesz szukać w regulaminach i politykach prywatności poszczególnych serwisów społecznościowych.</p>
        <p>Wtyczki serwisów społecznościowych gromadzą i przekazują informacje do administratorów tych serwisów nawet wtedy, gdy przeglądasz naszą stroną nie będąc zalogowanym do swojego konta w serwisie społecznościowym. Wtedy jednak
            przeglądarka wysyła bardziej ograniczony zestaw informacji.</p>
        <p>Jeśli zalogowałeś się do jednego z serwisów społecznościowych, to administrator serwisu będzie mógł bezpośrednio przyporządkować wizytę na naszej stronie do Twojego profilu w danym serwisie społecznościowym.</p>
        <p>Jeśli nie chcesz, aby serwisy społecznościowe przyporządkowywały dane zebrane w trakcie odwiedzin na naszej stronie internetowej bezpośrednio Twojemu profilowi w danym serwisie, to przed wizytą na naszej stronie musisz się wylogować z
            tego serwisu. Możesz również całkowicie uniemożliwić załadowanie na stronie wtyczek stosując odpowiednie rozszerzenia dla Twojej przeglądarki, np. blokowanie skryptów.</p>
        <p>Jeżeli chodzi o szczegóły związane z przetwarzaniem przez administratorów serwisów społecznościowych informacji gromadzonych przez wtyczki, w szczególności o cel i zakres gromadzenia danych oraz ich dalsze przetwarzanie i wykorzystanie
            przez administratorów, jak również możliwość kontaktu oraz Twoje prawa w tym zakresie i możliwość dokonania ustawień zapewniających ochronę Twojej prywatności, wszystko znajdziesz w politykach prywatności poszczególnych usługodawców:
        </p>
        <ul>
            <li>Facebook - https://www.facebook.com/privacy/explanation,</li>
            <li>LinkedIN - <a href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</a>,</li>
        </ul>

         <h4 class="title">
            14. Zmiany dotyczące Polityki prywatności
        </h4>
        <p>Zmiany w ustawodawstwie dotyczącym ochrony danych osobowych, a także inne czynniki mogą wpłynąć na zmiany w niniejszej Polityce Prywatności. O wszelkich zmianach poinformujemy bezzwłocznie użytkowników poprzez opublikowanie nowej
            wersji na stronach Serwisu.</p>
    </div>
</div>
</template>

<script>
export default {
    name: 'PolitykaPrywatnosci'
}
</script>
